@import "../../styles/base";

@prefix: empty;

.@{prefix} {
    margin: 32px 0;
    font-size: @font-size-base;
    line-height: 1.5;
    text-align: center;
    color: @disabled-color;

    &-image {
        height: 40px;
        margin-bottom: 8px;

        img {
            height: 100%;
        }

        svg {
            height: 100%;
            margin: auto;
        }
    }

    &-footer {
        margin-top: 16px;
    }

    :global {
        .icon-empty {
            &-ellipse {
                fill: #F5F5F5;
                fill-opacity: 0.8;
            }

            &-path {
                fill: #FAFAFA;
            }

            &-g {
                stroke: #D9D9D9;
                fill: #FFFFFF;
            }
        }
    }
}
