@import "~Styles/base";

@prefix: loading;

.@{prefix} {
    display: inline-block;

    &-icon {
        font-size: inherit;
        display: inline-block;
        margin-right: 4px;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;

        svg {
            width: 16px;
            display: inline-block;
            animation: loadingCircle 1s linear infinite;
        }
    }

    &-block {
        display: block;
        text-align: center;
        padding: 48px 0;
    }

    &-block &-icon {
        display: block;
        margin-bottom: 12px;
        margin-left: 0;
    }

    &-chain {
        display: block;
        margin: 0 auto;
        max-width: 370px;

        &-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            > span {
                display: block;
                width: 10px;
                height: 10px;
                background-color: @border-color-base;
                border-radius: 100%;
                transition: @transition-base;
            }
        }
    }
}
