@import "../../styles/base";

.page-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 48px 0;
    z-index: 1;
}

.container {
    max-width: 890px;
    position: relative;
    margin: 60px auto 0;
    background-color: @container-background;
    border-radius: @border-radius-base;
    padding: @padding-base;

    &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &.fluid {
        background-color: transparent;
        padding: 0;
    }
}

.layout-group {
    display: flex;
    align-items: stretch;
    margin-bottom: 8px;
}

section.section {
    flex: 1;
    width: 100%;
    background-color: @container-background;
    border-radius: @border-radius-base;
    padding: 0;
    margin-right: 10px;
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(30px);

    &:last-of-type {
        margin-right: 0;
    }
}

.section-title {
    font-size: 14px;
    font-weight: 400;
}

@media only screen and(max-width: @responsive-md) {
    main.container {
        max-width: calc(100% - @padding-base - @padding-base);
        margin-top: 32px;
    }
}

@media only screen and(max-width: @responsive-sm) {
    main.container {
        width: calc(100% - 32px) !important;
        height: auto !important;
        max-width: calc(100% - 32px) !important;
        margin-top: 24px;
    }

    .layout-group {
        display: block;
        margin-bottom: 16px;

        .section {
            margin-bottom: 16px;
        }
    }

    .section-title {
        font-size: @font-size-sm;
    }
}
