@import "~Styles/base";

@prefix: account-selector;

:global {
    .@{prefix} {
        margin-bottom: 12px;
        margin-top: 20px;
        width: 100%;

        &-wrapper {
            background-color: @component-background;
            border-radius: @border-radius-base;
            padding: @padding-base;
            z-index: @zindex-dropdown + 1;
        }

        &-title {
            font-size: 14px;
            font-weight: @font-weight-base;
            margin: 0;
        }

        .dropdown-overlay {
            padding: 0;
            max-height: 180px !important;
        }

        &.dropdown-with-arrow .dropdown-trigger:after {
            right: 26px;
        }

        &-item {
            &, &.dropdown-item {
                display: flex;
                align-items: center;
                height: 60px;
                padding: 0 46px 0 12px;
                font-size: @font-size-xs;
            }

            &-avatar {
                margin-right: 16px;
                pointer-events: none;
            }

            &-info {
                color: @text-color-secondary;

                > * {
                    margin: 0;
                }

                &-title {
                    margin-bottom: 2px;
                    color: @text-color-heading;
                    font-size: @font-size-sm;
                }
            }
        }
    }
}
