@import "../../styles/base";

@prefix: modal;

:global {
    .@{prefix} {
        padding: @padding-base;
        background-color: #FFFFFF;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        border: 1px solid #EEEEEE;
        position: fixed;
        margin: auto;
        z-index: @zindex-modal;
        width: 520px;
        max-width: calc(100vw - 32px);
        opacity: 1;
        animation: antMoveDownIn @animation-duration-slow;

        &-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: @zindex-modal;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        &-top-right {
            top: 50px;
            right: 24px;
            left: auto;
            bottom: auto;
            min-height: 100px;
        }

        &-center {
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 120px;
            min-height: 180px;
        }

        &-closing {
            opacity: 0;
            animation: antMoveDownOut @animation-duration-slow;
        }
    }
}

@media only screen and (max-width: @responsive-md) {
    .modal-top-right {
        right: 12px;
    }
}

@media only screen and (max-width: @responsive-sm) {
    .modal-top-right {
        right: 6px;
    }
}
