@import "../themes/default";

* {
    margin: 0;
    padding: 0;
}

html, body {
    width: 100%;
    height: 100%;
}

*::-ms-clear,
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

*,
&:before,
&:after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif; // 2
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // 6
}

body {
    color: @text-color;
    font-size: @font-size-base;
    font-family: @font-family;
    background-color: @body-background;
    font-feature-settings: 'tnum';
}

hr {
    height: 0; // 1
    overflow: visible; // 2
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: @heading-color;
    font-weight: 500;
}

p {
    margin-top: 0;
    margin-bottom: 1em;
}

b,
strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

a {
    color: @link-color;
    text-decoration: none;
    background-color: transparent; // remove the gray background on active links in IE 10.
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects; // remove gaps in links underline in iOS 8+ and Safari 8+.

    &:hover {
        color: @link-hover-color;
    }

    &:active {
        color: @link-active-color;
    }

    &:active,
    &:hover {
        text-decoration: none;
        outline: 0;
    }

    // https://github.com/ant-design/ant-design/issues/22503
    &:focus {
        text-decoration: none;
        outline: 0;
    }

    &[disabled] {
        color: @disabled-color;
        cursor: not-allowed;
    }
}

img {
    vertical-align: middle;
    border-style: none;
}

a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
    touch-action: manipulation;
}

table {
    border-collapse: collapse;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // remove the margin in Firefox and Safari
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible; // Show the overflow in Edge
}

button,
select {
    text-transform: none; // remove the inheritance of text transform in Firefox
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
[type="button"], /* 1 */
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
}

// remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. remove the padding in IE 10-
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    // remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto; // remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
}

progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of incement and decement buttons in Chrome.
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
}

//
// remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
}

template {
    display: none; // Add the correct display in IE
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
    display: none !important;
}

::selection {
    color: @text-color-inverse;
    background: @text-selection-bg;
}

// Utility classes
.clearfix {
    .clearfix();
}

ul, li, ol, dt, dd {
    list-style: none;
}

input::placeholder {
    color: @text-color-secondary;
    font-size: @font-size-base;
}
