@import "~Styles/base";

@prefix: transaction-process;

.@{prefix} {
    width: 436px;
    min-height: 514px;
    padding: 44px 24px 32px;

    &-title {
        font-size: @font-size-h1;
        color: @text-color-heading;
        margin-bottom: 22px;
    }

    &-description {
        font-size: @font-size-base;
        color: @text-color;
        margin-bottom: 20px;
    }

    &-loading {
        margin: 34px auto;
    }

    &-link {
        margin-bottom: 20px;
    }

    &-extra {
        margin-top: 34px;
        text-align: center;
        color: @text-color-heading;
        font-weight: @font-weight-bold;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 34px;
        margin-bottom: 0;
    }

    &-action {
        position: absolute;
        bottom: 34px;
        display: block;
        width: calc(100% - 48px);
        z-index: 1;
    }

    &-finished {
        &:after {
            content: ' ';
            display: block;
            position: absolute;
            background-image: url("~Assets/process-finish.png");
            background-repeat: no-repeat;
            background-size: 310px;
            top: 140px;
            left: 156px;
            background-position: right top;
            width: 310px;
            height: 310px;
        }
    }
}
