@import "../../styles/base";

@prefix: button;

.@{prefix} {
    display: inline-block;
    margin: 0;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    border: solid 1px @primary-color;
    padding: 0 20px;
    min-width: 120px;
    cursor: pointer;
    transition: all ease @animation-duration-slow;
    user-select: none;
    white-space: nowrap;

    &-large {
        width: 200px;
        height: 50px;
        line-height: 50px;
    }

    &-block {
        display: block;
        width: 100%;
    }

    &-small {
        height: 36px;
        line-height: 36px;
    }

    &-primary:not([disabled]):not(&-loading) {
        color: @text-color-inverse;
        background-color: @primary-color;

        &:hover,
        &:active {
            background-color: @hover-color;
        }
    }

    &-default:not([disabled]):not(&-loading) {
        color: @text-color;
        border-color: transparent;
        background-color: #FFF5F5;

        &:hover,
        &:active {
            color: @primary-color;
        }
    }

    &-outline {
        background-color: transparent;
    }

    &-default.@{prefix}-outline:not([disabled]):not(&-loading) {
        color: @text-color;
        font-weight: @font-weight-bold;
        border-color: @text-color;
        background-color: #FFFFFF;

        &:hover,
        &:active {
            color: @hover-color;
            border-color: @hover-color;
            box-shadow: 0 2px 12px rgba(252, 58, 146, 0.3);
        }
    }

    &-default.@{prefix}-outline.@{prefix}-round:not([disabled]):not(&-loading):not(:hover):not(:active) {
        border-color: @text-color-heading;
        color: @text-color-heading;
    }

    &-primary.@{prefix}-outline:not([disabled]):not(&-loading) {
        border-color: @primary-color;
        color: @primary-color;
        background-color: transparent;

        &:hover,
        &:active {
            color: @hover-color;
            border-color: @hover-color;
            box-shadow: 0 2px 12px rgba(252, 58, 146, 0.3);
        }
    }

    &-round {
        border-radius: 20px;
    }

    &-round.@{prefix}-large {
        border-radius: 25px;
    }

    &-loading,
    &[disabled] {
        cursor: not-allowed;
        color: @text-color-disabled;
        background-color: #EEEEEE;
        border-color: #EEEEEE;
    }

    &-loading {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    &-group {
        display: inline-block;

        > * {
            flex: 1;
        }

        .@{prefix} + .@{prefix} {
            margin-left: 16px;
        }
    }
}
