@import "./base";
@import './core/reset';
@import "./core/index";

body {
    width: 100%;
    height: 100%;
    background-color: @body-background;
    background-image: linear-gradient(129.83deg, #FFF7F5 19.77%, #FFEEEE 90.78%);
    background-image: url("../assets/background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}

h1 {
    font-size: @font-size-h1;
}

em {
    color: @primary-color;
    font-style: normal;
}

@media only screen and (max-width: @responsive-sm) {
    body {
        font-size: @font-size-sm;
    }
}
