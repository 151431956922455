@import "../../styles/base";

@prefix: dropdown;

.@{prefix} {
    display: inline-block;
    position: relative;
    overflow: visible;

    &-opened {
        display: inline-block;
    }

    &-blocked {
        display: block;
        width: 100%;
    }

    &-trigger {
        display: flex;
        cursor: pointer;
        position: relative;
        align-items: center;

        &:hover,
        &:active,
        .@{prefix}-opened & {
            border-color: @hover-color;
        }
    }

    &-bordered {
        .@{prefix}-trigger {
            border: @border-base;
            border-radius: @border-radius-base;
        }
    }

    &-with-arrow &-trigger {
        padding-right: 30px;

        &:after {
            content: ' ';
            background-image: url("../../assets/arrow-down.png");
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 8px;
            position: absolute;
            width: 8px;
            height: 8px;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 26px;
            transition: @transition-base;

            .@{prefix}-opened& {
                transform: rotate(180deg);
            }
        }
    }

    &-disabled &-trigger {
        pointer-events: none;
    }

    &-overlay {
        margin-top: 6px;
        display: none;
        height: 0;
        transition: @transition-base;
        background-color: @component-background;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        border-radius: @border-radius-base;
        overflow: hidden;
        position: absolute;
        z-index: @zindex-dropdown;
        padding: 12px 0;

        &-margin {
            display: none;
            height: 6px;
            width: 100%;
            position: absolute;
        }

        &-blocked {
            width: 100%;
        }
    }

    &-opened &-overlay {
        display: block;
        height: auto;
        max-height: 144px;
        overflow: auto;
        right: 0;
    }

    &-opened &-overlay-margin {
        display: block;
    }

    &-item {
        cursor: pointer;
        transition: @transition-base;
        padding: 6px @padding-base;
        display: flex;
        white-space: nowrap;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover,
        &:active {
            background-color: @hover-background;
        }
    }
}
