@import "../../styles/base";

.navigation {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    border-bottom: solid 1px #FFFFFF;
    white-space: nowrap;
    z-index: @zindex-notification;
    background-image: linear-gradient(129.83deg, #FFF7F5 19.77%, #FFEEEE 90.78%);
    box-shadow: 0 6px 16px -8px #00000014, 0 9px 28px #0000000D, 0 12px 48px 16px #00000008;

    .nav-container {
        max-width: 1440px;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
}

.navigation-logo {
    width: 186px;
    margin-right: 48px;
    transition: @transition-base;
    flex-shrink: 0;
}

.navigation-left-wrapper,
.navigation-right-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
}

.navigation-left-wrapper {
    flex: 1;

    li {
        display: block;
        position: relative;
        cursor: pointer;

        a {
            color: @text-color;
            display: block;
            width: 100%;
            min-width: 94px;
            padding: 0 10px;
            text-align: center;
        }

        &[active] {
            a {
                font-weight: 500;
            }
        }

        &[aria-selected="true"],
        &:hover,
        &:active {
            a {
                color: #333333;
            }

            &:after {
                content: ' ';
                position: absolute;
                bottom: 0;
                width: 78px;
                height: 4px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: @primary-color;
            }
        }
    }
}

.navigation-right-wrapper {
    height: 40px;
    line-height: 40px;
    border-radius: @border-radius-base;
    background-color: @component-background;
    padding: 0 32px 0 16px;
    min-width: 260px;
    position: relative;
    cursor: pointer;
    margin-left: auto;

    & > * {
        display: inline-block;
    }

    .amount-wrapper {
        flex: 1;
    }

    .balance {
        color: @text-color-heading;
        font-size: @font-size-base;
    }

    .unit {
        margin-left: 6px;
        font-size: @font-size-xs;
        color: @primary-color;
        font-weight: @font-weight-bold;
    }

    .name {
        color: @text-color-secondary;
        font-weight: @font-weight-bold;
        font-size: @font-size-sm;
        margin-left: 24px;
    }

    .avatar {
        margin-left: 14px;
        pointer-events: none;
    }

    &:after {
        content: ' ';
        top: 0;
        bottom: 0;
        right: 12px;
        width: 8px;
        height: 100%;
        position: absolute;
        background-size: 8px;
        background-image: url("../../assets/arrow-down.png");
        background-position: center right;
        background-repeat: no-repeat;
        transition: transform ease @animation-duration-slow;
    }

    &.opened:after {
        transform: rotate(180deg);
    }
}

.navigation-trigger {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: none;
    outline: none;
    display: none;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    flex-shrink: 0;
}

.navigation-menu-trigger {
    .navigation-trigger;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaBAMAAADKhlwxAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAbUExURUxpceGKit+Jid+JieCKiuGLi+CLi+CKiuGLi7UcG6sAAAAIdFJOUwC/gCqY5mCwjxzz5wAAAIVJREFUWMNjYBgFo2AUjEDApoQfJCArZo7owA9akVWzdBACCkiqGQmqLiBJtQBJLkE2m5mgapRASSSgOGw0kY0CIDARxA+cSctpBiSlbwey8w61c5oD2b4kLQRHwYgFqQRSlRhKBUgwxSKnQXYa5jR2klQzkeQSBtJyGkmtglEwCkbBSAEA10pusQNl3ZAAAAAASUVORK5CYII=");
    background-size: 30px;
    margin-right: 24px;
    transition: @transition-base;

    &:active {
        background-color: rgba(0, 0, 0, .1);
    }
}

.navigation-wallet-trigger {
    .navigation-trigger;

    background-size: 24px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIBAMAAACnw650AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUxpcf05k/w5kv85lfw5kfs5kv06kv8/lPw5kvs3k/w5kvs4kfw5kvw6klXm+LYAAAANdFJOUwCf3yCF714Qvz3QcLDf9tjHAAABi0lEQVRIx+3Wv0vDQBQH8Kcp1kqFuqhjkW4aCIqgmz/oKDi4SUBQdBK6CI4FB6eCg5sU+gcouIh0E0fBIbHVWn3/izG9JPfi3csJuvW7JXzoy728uxRgmL/P1aOLJN3rdorkq6hIxSHoDpWpyOYUNTlLjGXrkF+L0QRqU4/Rlh514mp6g92o3giDcE2gUQ5tC9Tk0Dt57v6UyKzyyRukOMCyjD7FzZtwGUlvyUJ8cXNwlfS2SHpAUFKOLoSg7lyUweX0/lIYglJvdi81cSo0A9moAwboIZjowzAXWuQ7YDXob/5EHsCisk9yXgBsZZ/klGEMcXPVZlEdxr9LNllUCvZG8MSTGaiIPYBjFrUghzh/4LLoDfJu5uo+RCkWBeNYsOO+6t5dMIGF9XBb3OvRq8kU4K0J8hwDhN6RAcLMFiiQa4JszvQFeuZQL/vITDaC0SF2wqGyQDkOlaIjmuuB85vDPtg/2rRMPkDSMKzo0Lk8CFW12SXTYm2ozE4tNXmXT+kP9UJ7+PflH/IFcd59tg97kJ4AAAAASUVORK5CYII=");
    background-color: @component-background;
    border-bottom-left-radius: @border-radius-sm;
    margin-left: auto;
}

@media only screen and(max-width: @responsive-xl) {
    .navigation-logo {
        margin-right: 12px;
    }
}

@media only screen and (max-width: @responsive-lg) {
    .navigation-menu-trigger {
        display: block;
    }

    .navigation-left-wrapper {
        display: none;
        position: fixed;
        left: 0;
        top: 48px;
        bottom: 0;
        background-color: @component-background;
        z-index: @zindex-dropdown;
        box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05),
        12px 0 48px 16px rgba(0, 0, 0, 0.03);
        width: 248px;

        > li {
            padding-left: 32px;
            margin: 0;
            position: relative;

            a {
                text-align: left;
            }

            &[aria-selected="true"],
            &:active,
            &:hover {
                background-color: #FFF7F5;

                &:after {
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: auto;
                    width: 3px;
                    height: 100%;
                }
            }
        }

        &.visible {
            display: block;
        }
    }
}

@media only screen and (max-width: @responsive-sm) {
    .navigation .nav-container {
        padding-right: 0;
    }

    .navigation-logo {
        margin: 0 auto;
    }

    .navigation-wallet-trigger {
        display: block;
    }

    .navigation-right-wrapper {
        display: none;

        &.visible {
            display: flex;
            position: fixed;
            right: 6px;
            top: 50px;

        }
    }

    .account-selector-wrapper {
        top: 94px;
    }
}
