@import "../../styles/base";

@prefix: dimmer;

:global {
    .@{prefix} {
        position: fixed;
        z-index: @zindex-modal-mask;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(51, 51, 51, 0.3);
    }
}

